import {
  BRAND_PREFIX,
  FILE_PREFIX,
  includeResponsive,
  PREFIX,
} from "@/utils/imagePrefixes";

const brand = {
  logoHeaderMobile: `${BRAND_PREFIX}heroLogoMobile.svg`,
  logoNav: `${BRAND_PREFIX}heroLogo.svg`,
  logoHeader: `${BRAND_PREFIX}brand/logoHeader.png`,
  logoProduct: `${BRAND_PREFIX}brand/logoProduct.png`,
  logoProductDark: `${BRAND_PREFIX}brand/logoProductDark.png`,
  logoHeaderDesktop: `${BRAND_PREFIX}brand/logoHeaderDesktop.png`,
  whatsBtn: `${BRAND_PREFIX}/whatsapp.svg`,
  logoFooter: `${BRAND_PREFIX}logoFooter.svg`,
};

const ui = {
  menuClosed: `${PREFIX}ui/menuClosed.svg`,
  pointer: `${PREFIX}ui/pointer.png`,
  accordionChevron: `${PREFIX}ui/accordionChevron.svg`,
  ibama: `${PREFIX}ui/ibama.svg`,
  arrowNext: `${PREFIX}ui/arrowNext.svg`,
  arrowPrev: `${PREFIX}ui/arrowPrev.svg`,
};

const stamps = {
  stamp1: `${PREFIX}stamps/stamp-1.png`,
  stamp2: `${PREFIX}stamps/stamp-2.png`,
  stamp3: `${PREFIX}stamps/stamp-3.png`,
  stamp4: `${PREFIX}stamps/stamp-4.png`,
};

const INTERNAL_360_PATH = `${PREFIX}internal360/desktop/`;
const INTERNAL_360_PATH_MOBILE = `${PREFIX}internal360/mobile/`;

const heroContent = {
  firstContent: `${PREFIX}HeroDesktop/firstContent.svg`,
};

const heroImages = {
  turboFlex: {
    thumb: includeResponsive(
      `HeroDesktop/thumbs/fiat-toro-motor-turbo-diesel`,
      "webp"
    ),
  },
  superTelas: {
    thumb: includeResponsive(
      `HeroDesktop/thumbs/fiat-toro-espaco-interno`,
      "webp"
    ),
  },
  tecnologia: {
    thumb: includeResponsive(`HeroDesktop/thumbs/fiat-toro-tecnologia`, "webp"),
  },
  carga: {
    thumb: includeResponsive(
      `HeroDesktop/thumbs/fiat-toro-capacidade-carga-thumb`,
      "webp"
    ),
  },
  conforto: {
    thumb: includeResponsive(
      `HeroDesktop/thumbs/fiat-toro-forca-conforto-thumb`,
      "webp"
    ),
  },
  kv: includeResponsive(
    `HeroDesktop/backgrounds/fiat-toro-2024`,
    "webp"
  ),
  heroTitleDestkop: `${PREFIX}HeroDesktop/toro-2025-lider-desktop.webp`,
  heroTitleMobile: `${PREFIX}HeroDesktop/toro-2025-lider-mobile.webp`,
};

const designImage = {
  desktop: `/Design/desktop/familia-de-picapes-toro@3x.webp`,
  mobile: `/Design/mobile/familia-de-picapes-Toro-mobile@3x.webp`,
};
const bannerImages = {
  desktop: includeResponsive(`/Banner/banner`, "webp"),
  mobile: includeResponsive(`Banner/banner-mobile`, "webp"),
  toro: "/images/Banner/toro-banner.webp",
};

const Photos = {
  backgroundImage: includeResponsive("Photos/nova-rampage-roda", "webp"),
  backgroundImage2: includeResponsive("Photos/nova-rampage-traseira", "webp"),
};
const ProdDetails = {
  backgroundImage: includeResponsive(
    "ProductDetails/background/bg-features-rampage",
    "webp"
  ),
};

const panoramaImage = `${PREFIX}Panorama/image.jpg`;

const panoramaImagePreview = `${PREFIX}Panorama/preview.png`;

const files = {
  accessoriesCatalogue: `${FILE_PREFIX}ram-rampage.pdf`,
};

export * as productDetails from "./productDetails";

export {
  bannerImages,
  brand,
  designImage,
  files,
  heroContent,
  heroImages,
  INTERNAL_360_PATH,
  INTERNAL_360_PATH_MOBILE,
  panoramaImage,
  panoramaImagePreview,
  Photos,
  ProdDetails,
  stamps,
  ui,
};
