import { includeResponsive } from "@/utils/imagePrefixes";

const PREFIX = `${process.env.BASE_PREFIX}images/`;
const SECTION_PREFIX = "ProductDetails/";

const DESIGN_PREFIX = `${SECTION_PREFIX}design/`;
export const design = {
  novaGrade: includeResponsive(`${DESIGN_PREFIX}nova-grade-frontal`, "webp", {
    prefix: PREFIX,
  }),
  farolLed: includeResponsive(
    `${DESIGN_PREFIX}rodas-liga-leve-farois-full-led`,
    "webp",
    { prefix: PREFIX }
  ),
  novasRodas: includeResponsive(
    `${DESIGN_PREFIX}roda-pneus-atr-plus`,
    "webp",
    { prefix: PREFIX }
  ),
  overbumper: includeResponsive(
    `${DESIGN_PREFIX}overbumper-integrado`,
    "webp",
    { prefix: PREFIX }
  ),
};

const CONFORTO_PREFIX = `${SECTION_PREFIX}conforto/`;
export const consforto = {
  bancosCouro: includeResponsive(`${CONFORTO_PREFIX}acabamento-premium`, "webp", {
    prefix: PREFIX,
  }),
  multilink: includeResponsive(
    `${CONFORTO_PREFIX}suspensao-multilink`,
    "webp",
    { prefix: PREFIX }
  ),
  volanteCouro: includeResponsive(
    `${CONFORTO_PREFIX}volante-revestido-couro`,
    "webp",
    { prefix: PREFIX }
  ),
  acabamento: includeResponsive(
    `${CONFORTO_PREFIX}mais-espaco-acabamento-premium`,
    "webp",
    { prefix: PREFIX }
  ),
};

const PERFORMANCE_PREFIX = `${SECTION_PREFIX}performance-carga/`;
export const performance_carga = {
  motor: includeResponsive(`${PERFORMANCE_PREFIX}motor-turbo-diesel`, "webp", {
    prefix: PREFIX,
  }),
  tracao: includeResponsive(`${PERFORMANCE_PREFIX}tracao-picape-4x4`, "webp", {
    prefix: PREFIX,
  }),
  capacidade: includeResponsive(
    `${PERFORMANCE_PREFIX}capacidade-de-carga`,
    "webp",
    { prefix: PREFIX }
  ),
};

const TECNOLOGIA_PREFIX = `${SECTION_PREFIX}tecnologia/`;
export const tecnologia = {
  connectme: includeResponsive(
    `${TECNOLOGIA_PREFIX}fiat-toro-super-conectada`,
    "webp",
    { prefix: PREFIX }
  ),
  ar: includeResponsive(
    `${TECNOLOGIA_PREFIX}ar-condicinado-digital-dual-zone`,
    "webp",
    { prefix: PREFIX }
  ),
  keyless: includeResponsive(`${TECNOLOGIA_PREFIX}keyless`, "webp", {
    prefix: PREFIX,
  }),
  multimidaa: includeResponsive(
    `${TECNOLOGIA_PREFIX}central-multimidia-10`,
    "webp",
    { prefix: PREFIX }
  ),
  frenagemAt: includeResponsive(
    `${TECNOLOGIA_PREFIX}frenagem-automatica`,
    "webp",
    { prefix: PREFIX }
  ),
  peermanenciaFaixa: includeResponsive(
    `${TECNOLOGIA_PREFIX}aviso-saida-pista`,
    "webp",
    { prefix: PREFIX }
  ),
  comutacao: includeResponsive(`${TECNOLOGIA_PREFIX}comutacao-farol`, "webp", {
    prefix: PREFIX,
  }),
  airbag: includeResponsive(`${TECNOLOGIA_PREFIX}6-airbags`, "webp", {
    prefix: PREFIX,
  }),
  controleTracao: includeResponsive(
    `${TECNOLOGIA_PREFIX}controle-tracao-sensor-dianteiro`,
    "webp",
    { prefix: PREFIX }
  ),
};

const SERVICOS_PREFIX = `${SECTION_PREFIX}servicos/`;
export const servicos = {
  revisoes: includeResponsive(`${SERVICOS_PREFIX}revisao-sob-medida`, "webp", { prefix: PREFIX }),
  fiatFlex: includeResponsive(`${SERVICOS_PREFIX}pacote-servicos`, "webp", { prefix: PREFIX }),
};

const ACESSORIOS_PREFIX = `${SECTION_PREFIX}acessorios/`;
export const acessorios = {
  capota: includeResponsive(`${ACESSORIOS_PREFIX}capota-eletrica`, "webp", {
    prefix: PREFIX,
  }),
  extensorCacamba: includeResponsive(
    `${ACESSORIOS_PREFIX}extensor-cacamba`,
    "webp",
    { prefix: PREFIX }
  ),
  engateReboque: includeResponsive(
    `${ACESSORIOS_PREFIX}engate-reboque`,
    "webp",
    {
      prefix: PREFIX,
    }
  ),
  estriboTubular: includeResponsive(
    `${ACESSORIOS_PREFIX}estribo-tubular`,
    "webp",
    { prefix: PREFIX }
  ),
};
